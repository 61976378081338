let AQUAIcon = './public/icons/blue-icon.svg'
let  CAKEIcon =  './public/icons/CAKE.svg'
let  ETHIcon =  './public/icons/ETH.svg'
let  BTCBIcon =  './public/icons/BTCB.svg'
let  BUSDIcon =  './public/icons/BUSD.png'
let  BNBIcon =  './public/icons/BNB.png'
let  USDTIcon =  './public/icons/USDT.svg'
let  VAIIcon =  './public/icons/VAI.png'
let  USTIcon =  './public/icons/UST.png'
let  USDCIcon =  './public/icons/USDC.svg'
let  TUSDIcon =  './public/icons/TUSD.svg'
let  DAIIcon =  './public/icons/DAI.svg'
let  FourBeltIcon =  './public/icons/4belt-icon.png'
let  GAMMAIcon =  './public/icons/gamma-icon.png'
let  LUNAIcon =  './public/icons/LUNA.png'
let  LINKIcon =  './public/icons/LINK.svg'
let  DOTIcon =  './public/icons/DOT.svg'
let  ADAIcon =  './public/icons/ADA.svg'
let  DOGEIcon =  './public/icons/DOGE.svg'
let  XRPIcon =  './public/icons/XRP.svg'
let  UNIIcon =  './public/icons/Uni.svg'
let  LTCIcon =  './public/icons/LTC.svg'
let  XVSIcon =  './public/icons/XVS.svg'
let  FRAXIcon =  './public/icons/FRAX.svg'
let  MBOXIcon =  './public/icons/MOBX.png'
let  MATICIcon =  './public/icons/MATIC.svg'
let  SOLIcon =  './public/icons/SOL.svg'
let  AUSTIcon =  './public/icons/aUST.png'
let  AVAX =  './public/icons/AVAX.svg'
let  FTMIcon =  './public/icons/FTM.svg'
let  AtomIcon =  './public/icons/atom_icon.png'
let  whustIcon =  './public/icons/whust_icon.svg'
let  stableSwap3GIcon =  './public/icons/3g-icon.png'
let  stableSwapIcon =  './public/icons/stable-swap-icon.png'
let  hayIcon =  './public/icons/hay_icon.png'
let  bnbxIcon =  './public/icons/BNBx.png'
let  gammaInfinityIcon =  './public/icons/gamma_inf.png'
let  aquaInfinityIcon =  './public/icons/aqua_inf.png'
let ankrBNB = './public/icons/ankrBNB.png'

const getTokenIcon = (tokenName) => {
    const token = tokenName.toLowerCase()

    if (token=='aqua') {
        return AQUAIcon
    }
    if (token=='gamma') {
        return GAMMAIcon
    }
    if (token=='luna') {
        return LUNAIcon
    }
    if (token=='acac') {
        return AQUAIcon
    }
    if (token=='cake') {
        return CAKEIcon
    }
    if (token=='eth') {
        return ETHIcon
    }
    if (token=='btcb') {
        return BTCBIcon
    }
    if (token=='btc') {
        return BTCBIcon
    }
    if (token=='busd') {
        return BUSDIcon
    }
    if (token=='bnb') {
        return BNBIcon
    }
    if (token=='wbnb') {
        return BNBIcon
    }
    if (token=='cake') {
        return CAKEIcon
    }
    if (token=='usdt') {
        return USDTIcon
    }
    if (token=='usdc') {
        return USDCIcon
    }
    if (token=='dai') {
        return DAIIcon
    }
    if (token=='ust') {
        return USTIcon
    }
    if (token=='tusd') {
        return TUSDIcon
    }
    if (token=='vai') {
        return VAIIcon
    }
    if (token=='4belt') {
        return FourBeltIcon
    }
    if (token=='link') {
        return LINKIcon
    }
    if (token=='dot') {
        return DOTIcon
    }
    if (token=='xrp') {
        return XRPIcon
    }
    if (token=='doge') {
        return DOGEIcon
    }
    if (token=='ada') {
        return ADAIcon
    }
    if (token=='ltc') {
        return LTCIcon
    }
    if (token=='uni') {
        return UNIIcon
    }
    if (token=='xvs') {
        return XVSIcon
    }
    if (token=='frax') {
        return FRAXIcon
    }
    if (token=='mbox') {
        return MBOXIcon
    }
    if (token=='matic') {
        return MATICIcon
    }
    if (token=='sol') {
        return SOLIcon
    }
    if (token=='aust') {
        return AUSTIcon
    }
    if (token=='avax') {
        return AVAX
    }
    if (token=='ftm') {
        return FTMIcon
    }
    if (token=='atom') {
        return AtomIcon
    }
    if (token=='xrp') {
        return XRPIcon
    }
    if(token=='whust'){
        return whustIcon
    }
    if(token=='3glp'){
        return stableSwap3GIcon
    }
    if(token=='busd-usdt-usdc'){
        return stableSwap3GIcon
    }
    if(token=='3g'){
        return stableSwapIcon
    }
    if(token=='hay'){
        return hayIcon
    }
    if(token=='bnbx'){
        return bnbxIcon
    }
    if(token=='gamma infinity'){
        return gammaInfinityIcon
    }
    if(token=='aqua infinity') {
        return aquaInfinityIcon
    }
    if(token == 'ankrbnb'){
        return ankrBNB
    }
}
